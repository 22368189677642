import React from "react";
import "./Hero.css";
import saturn from "./assets/logo.png";

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        {/* Left Side: Text */}
        <div className="hero-text">
          <h1>Zohal</h1>
          <p className="hero-subtitle">Decentralized Perpetual Exchange</p>
          <p className="hero-description">
            Trade BTC, ETH, STRK, and other top cryptocurrencies with up to 100x
            leverage directly from your wallet.
          </p>
          <a
            href="https://app.zohal.xyz"
            target="_blank"
            rel="noopener noreferrer"
            className="hero-cta"
          >
            Launch App
          </a>
        </div>

        {/* Right Side: Saturn Animation */}
        <div className="hero-animation">
          <img src={saturn} alt="Saturn Logo" className="saturn-logo" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
