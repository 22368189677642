import React from "react";
import "./Ecosystem.css";
import starknetLogo from "./assets/icons/starknet.png"; // Replace with your Starknet logo path
import pragmaLogo from "./assets/icons/pragma.png"; // Replace with your Pragma logo path
import starkwareLogo from "./assets/icons/starkware.png"; // Replace with StarkWare logo path

const Ecosystem = () => {
  return (
    <section className="ecosystem">
      <h2>Ecosystem</h2>
      <p className="ecosystem-subtitle">
        Powered by Starknet, Pragma price feeds, and backed by Starknet Foundation and StarkWare.
      </p>

      {/* Ecosystem Logos */}
      <div className="ecosystem-logos">
        <img src={starkwareLogo} alt="StarkWare Logo" className="ecosystem-logo" />
        <img src={starknetLogo} alt="Starknet Logo" className="ecosystem-logo" />
        <img src={pragmaLogo} alt="Pragma Logo" className="ecosystem-logo" />
      </div>

      {/* Call to Action */}
      <div className="ecosystem-cta">
        <p>Get started in seconds</p>
        <a
          href="https://app.zohal.xyz"
          target="_blank"
          rel="noopener noreferrer"
          className="start-trading"
        >
          Start Trading →
        </a>
      </div>
    </section>
  );
};

export default Ecosystem;
