import React from "react";
import "./Feature.css";
import starkentIcon from "./assets/icons/starknet.png";
import marketIcon from "./assets/icons/market.png";
import orderIcon from "./assets/icons/order.png";
import fundingIcon from "./assets/icons/funding.png";
import liquidationIcon from "./assets/icons/liquidation.png";
import costIcon from "./assets/icons/cost.png";
import swapIcon from "./assets/icons/swap.png";

const Feature = () => {
  return (
    <section className="features">
      <h2>Features</h2>
      <p className="features-subtitle">
        Everything you need for fast and secure on-chain trading.
      </p>

      {/* First Row */}
      <div className="feature-grid">
        <div className="feature-card">
          <img src={starkentIcon} alt="Starknet Icon" className="feature-icon" />
          <h3>Starknet Network</h3>
          <p>Discover seamless, secure trading at reduced costs.</p>
        </div>
        <div className="feature-card">
          <h3 className="highlighted">From 0.02%</h3>
          <p>Lowest transaction fees. Maximize earnings with low trading fees.</p>
        </div>
        <div className="feature-card">
          <img src={marketIcon} alt="Markets Icon" className="feature-icon" />
          <h3>10+ Markets</h3>
          <p>Trade using crypto collateral.</p>
        </div>
        <div className="feature-card">
          <img src={orderIcon} alt="Order Icon" className="feature-icon" />
          <h3>Advanced Order Types</h3>
          <p>Submit limit, stop, take-profit.</p>
        </div>
      </div>

      {/* Second Row */}
      <div className="feature-grid">
        <div className="feature-card">
          <img src={fundingIcon} alt="Funding Icon" className="feature-icon" />
          <h3>Dynamic Funding</h3>
          <p>Longs pay shorts and vice versa based on OI imbalance.</p>
        </div>
        <div className="feature-card">
          <img src={liquidationIcon} alt="Liquidation Icon" className="feature-icon" />
          <h3>Reduce Liquidation Risks</h3>
          <p>
            An aggregate of high-quality price feeds determines when
            liquidations occur. This keeps positions safe from temporary wicks.
          </p>
        </div>
        <div className="feature-card">
          <img src={costIcon} alt="Cost Icon" className="feature-icon" />
          <h3>Save on Costs</h3>
          <p>
            Enter and exit positions with minimal spread and low price impact.
            Get optimal prices without incurring additional costs.
          </p>
        </div>
        <div className="feature-card">
          <img src={swapIcon} alt="Swap Icon" className="feature-icon" />
          <h3>Simple Swaps</h3>
          <p>
            Open positions through a simple swap interface. Conveniently swap
            from any supported asset into the position of your choice.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Feature;
