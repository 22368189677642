import React from "react";
import "./App.css";
import Header from "./Header";  
import Hero from "./Hero";
import Feature from "./Feature";
import Ecosystem from "./Ecosystem";
import Footer from "./Footer";

const App = () => {
  return (
    <div className="app">
      <Header />

      <main className="main">
        <Hero />
        <Feature />
        <Ecosystem /> 
      </main>

      <Footer />
    </div>
  );
};

export default App;
