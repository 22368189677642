import React from "react";
import "./Footer.css";
import logo from "./assets/icons/starknet.png"; 
import xIcon from "./assets/icons/x.png"; 
import githubIcon from "./assets/icons/github.png"; 
import telegramIcon from "./assets/icons/telegram.png";
import discordIcon from "./assets/icons/discord.png"; 

const Footer = () => {
  return (
    <footer className="footer">
      {/* Logo and Brand */}
      <div className="footer-brand">
        <img src={logo} alt="Logo" className="footer-logo" />
        <span className="footer-title">Zohal</span>
      </div>

      {/* Icons */}
      <div className="footer-icons">
        <a href="https://x.com/zohal_starknet" target="_blank" rel="noopener noreferrer">
          <img src={xIcon} alt="Twitter" className="footer-icon" />
        </a>
        <a href="https://github.com/Zohal-Starknet" target="_blank" rel="noopener noreferrer">
          <img src={githubIcon} alt="GitHub" className="footer-icon" />
        </a>
      </div>

      {/* Links */}
      <div className="footer-links">
        <a href="/terms" className="footer-link">
          Terms and Conditions
        </a>
        <a href="/referral" className="footer-link">
          Referral Terms
        </a>
        <a href="/media-kit" className="footer-link">
          Media Kit
        </a>
      </div>
    </footer>
  );
};

export default Footer;
